@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Inter", sans-serif;
  -webkit-tap-highlight-color: transparent;
}

body {
  @apply overflow-x-hidden;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb:hover {
}

::-webkit-scrollbar-thumb {
  @apply bg-bg-grey-young;
  border-radius: 20px;
}

.accordion-item {
  @apply w-full h-0 max-h-0 invisible pl-5;
  transition: all 0.25s ease-in;
}

.accordion-item.show {
  @apply h-10 max-h-full visible;
  transition: all 0.25s ease-in;
}

.button {
  @apply py-3 px-5 font-medium text-white rounded-lg flex gap-2 items-center justify-center w-max mt-8;
}

.input-form-box {
  @apply flex flex-col gap-2;
}

.input-form-box label {
  @apply text-sm;
}

.input-form-box input,
select,
textarea {
  @apply border-bgrey rounded-lg py-3 px-3 focus:outline-none mb-1 border;
}

.input-form-box select {
  @apply py-3;
}

.input-form-box textarea {
  @apply h-48 w-full;
}

.preview-img {
  @apply w-28 h-max rounded-lg bg-slate-100 mb-12 overflow-hidden;
}

.preview-img img {
  @apply object-cover;
}
.link-sidebar {
  @apply flex items-center justify-between gap-4 mt-6 text-white;
}

.text-table {
  @apply ml-2;
}

.company-list-detail {
  @apply grid grid-cols-2 gap-2 text-semi-black;
}

.company-list-detail span {
  @apply font-semibold;
}

.button-category button {
  @apply px-5 py-1 bg-bg-grey-young rounded-lg;
}

.button-category button.active {
  @apply bg-primary text-white;
}

/* bg-white text-secondary p-3 font-semibold rounded-xl */
